<template>
  <div id="app">
    <div class="box" style="height: 100%;">
      <router-view></router-view>

    </div>
  </div>
</template>

<script>
import "@/assets/static/fonsizebs64/icon.css"

export default {
  name: 'ShipeaseFrontDeskApp',

  data() {
    return {
      yuming: '',
      title: ''
    };
  },

  created() {
  },

  methods: {

  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1200px) {
  #app {
    height: max-content;
    // width: max-content;
  }

  .el-container {
    height: max-content;
    width: max-content;
  }
}

// xl
@media screen and (max-width: 1500px) {
  .box {
    display: table;
  }
}

.el-message {
  top: 120px !important;
}

.el-dialog__title {
  font-family: hp_font;
  font-weight: 500;
  padding: 20px 10px;
}

.el-dialog__body {
  border-top: 1px solid #eee;
  padding: 30px 30px;
}

.el-dialog__header {
  background-color: #f6f6f6;
}

html {
  height: 100%;
  background-color: #f5f5f5;
}

body {
  height: 100%;
}

#app {
  height: 100%;
  background-color: #f5f5f5;
}

@font-face {
  font-family: hp_font;
  src: url("./assets/static/fonsize/hhpu.ttf");
}

* {
  font-family: hp_font !important;
  // font-weight: 400;

  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
