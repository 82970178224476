
// 导入i18n
import Vue from'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// 准备翻译的语言环境信息
import en from './lang/en'
import cn from './lang/cn'

const messages = {
    en,
    cn
   
  }
  // 通过选项创建 VueI18n 实例
  export default new VueI18n({
    locale: 'cn', // 设置地区
    messages // 设置地区信息
  })