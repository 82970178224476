<template>
    <div style="width: 100%;">
        <el-breadcrumb separator="/">
            <template v-for="item in breadcrumbList">
                <template v-for="rout in routerlist">
                    <el-breadcrumb-item v-if="item.name == rout.name">
                        <span>
                            {{ $t("setyj." + [item.name]) }}</span>
                    </el-breadcrumb-item>
                    <template v-for="r in item.children">
                        <el-breadcrumb-item v-if="r.name == rout.name">
                            <!-- {{ r.title }} -->
                            {{ $t("seterj." + [r.name]) }}
                        </el-breadcrumb-item>
                    </template>
                </template>
            </template>
        </el-breadcrumb>
    </div>
</template>

<script>

export default {
    name: 'ShipeaseYetitle',
    data() {
        return {
            routname: "",
            breadcrumbList: [],
            routerlist: [],
        };
    },
    watch: {
        $route(to) {
            this.routerlist = to.matched
        },
    },
    mounted() {
        let mun = localStorage.getItem('menu')
        if (mun) {
            this.breadcrumbList = JSON.parse(mun)
        }
        this.routerlist = this.$route.matched

    },

    methods: {
    },
};
</script>

<style lang="scss" scoped></style>