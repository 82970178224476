import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, getTime } from '@/utils/auth'
import Vue from 'vue'


let remtoken = true
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 500000 // request timeout
})
let loadingInstance1 = ''
// request interceptor 请求拦截器
service.interceptors.request.use(
  config => {

    let token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
      remtoken = true
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

/**
 * blob数据转json
 * @param {*} blob 
 * @returns 
 */
function blobToJson(blob) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        const responseText = e.target.result;
        const responseJson = JSON.parse(responseText);
        resolve(responseJson);
      };
      reader.readAsText(blob);
    } catch (err) {
      reject(err);
    }
  });
}

// response interceptor 响应拦截器
service.interceptors.response.use(
  async (response) => {
    let res = null;
    if (response.config.responseType === 'blob') {
      if (response.data.type !== 'application/json') {
        return response;
      }
      res = await blobToJson(response.data);
    } else if (response.config.responseType === 'text') {
      return response;

    }
    else {
      res = response.data;
    }

    // if the custom code is not 20000, it is judged as an error.
    const codeArr = [4011, 4012, 4013, 4014, 4015, 4016];
    // const codeArr = [4000];

    if (codeArr.indexOf(res.code) !== -1) {
      if (remtoken) {
        // token过期或无效
        Message({
          type: 'warning',
          message: '登陆过期'
        });
        localStorage.clear() || sessionStorage.clear()
        store.commit('updateactive', '1')
        // window.location.href='/'
        router.replace('/');
        remtoken = false
      }
      return false;
    }
    if (res.code !== 200 && res.code !== 400 && res.code !== 300 && res.code !== 304) {
      Message({
        message: res.msg || '系统异常,请联系管理员',
        type: 'error',
        duration: 5 * 1000
      })
      Vue.prototype.$loading().close()
      return Promise.reject(new Error(res.msg || '系统异常,请刷新页面或者联系管理员'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message || '错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
