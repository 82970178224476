import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: window.localStorage.getItem("token"),
    menua: window.localStorage.getItem("menua"),
    khid: "",
    name: "",
    username: "",
    activeR: "",
    zhuti: "",
    stremenu: "{}",
    userdata: "",
    aetiup: "",
    huobibi: "",
    credit: "",
    hdid: "",
    userty: {},
    statei18n: '',
  },
  getters: {

    huobibi(state) {
      return state.huobibi;
    },
    getuser(state) {
      return JSON.parse(state.userdata);
    },
    getstremenu(state) {
      return JSON.parse(state.stremenu);
    },
    //客户id
    khid(state) {
      let res = window.localStorage.getItem("khdata");
      let ret = JSON.parse(res);
      state.khid = ret.khid || "";
      return state.khid;
    },
    // 客户名字
    name(state) {
      if (!state.khid) {
        let res = window.localStorage.getItem("khdata");
        state.name = JSON.parse(res).sysname;
      }
      return state.name;
    },
    //用户名字
    username(state) {
      return state.username;
    },
  },
  mutations: {
    // 中英文
    cneni18(store, data) {
      store.statei18n = data;
    },
    // 货币
    huobiCenteer(store, data) {
      store.huobibi = data;
    },
    // 用户数据
    setuserdata(store, data) {
      store.userdata = data;
    },
    // 菜单栏
    setstremenu(store, data) {
      store.stremenu = data;
    },
    theme(store, data) {
      store.zhuti = data;
      return store.zhuti;
    },
    khidment(store, data) {
      window.localStorage.setItem("khdata", JSON.stringify(data));
      let jui = window.localStorage.getItem("khdata");
      store.khid = JSON.parse(jui).khid;
      store.name = JSON.parse(jui).sysname;
      store.userty = JSON.parse(jui);
    },
    kuhuuser: (store, data) => {
      window.localStorage.setItem("sherdata", JSON.stringify(data));
      let jui = window.localStorage.getItem("sherdata");

      store.username = JSON.parse(jui).uname;
      store.credit = JSON.parse(jui).credit;
      store.hdid = JSON.parse(jui).hdid;
    },
    updateactive: (state, data) => {
      window.sessionStorage.setItem("active", data);
      state.activeR = data;
    },
  },
  actions: {},
  modules: {},
});
