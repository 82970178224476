import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
Vue.use(UmyUi);
//导入面包屑
import yetitle from "@/views/components/yetitle.vue"
Vue.component('yetitle', yetitle)
// 导入i18n
import i18n from '@/i18n'
//导入moment
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment;

// 安装elementUI插件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/theme/index.css"
Vue.use(ElementUI);
// import shuiyin from "@/utils/shuiyin"
// Vue.use(shuiyin);
// 如果需要全局注册
// router.afterEach(item => {
//   if (item.path != '/' && item.path != '/Trialpricecalculation') {
//     Vue.use(shuiyin);

//   }
// })
// //把 `axios` 加到 `Vue` 的原型中
Vue.prototype.axios = axios;

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
