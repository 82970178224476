export default {
    // 首页翻译
    home: {
        gglb1: '公告列表',
        gglb2: '标题',
        gglb3: '发布时间',
        gglb4: 'SKU预警',
        gglb5: '库存管理',
        gglb6: '订单统计',
        gglb7: '开始日期',
        gglb8: '结束日期',
        gglb9: '今日',
        gglb10: '昨日',
        gglb11: '近7日',
        gglb12: '近30日',
        gglb13: '全部',
        gglb14: '财务信息',
        gglb15: '可用额度=余额',
        gglb16: '授信',
        gglb17: '临额',
        gglb18: '冻结',
        gglb19: '工单统计',
        gglb20: '最新公告',
        gglb21: '更多...',
        gglb22: '工单待回复',
        gglb23: '等待回复',
    },
    // 菜单翻译
    setyj: {
        kongzhitai: '控制台',
        guoneifahuo: '国内大货',
        zxxb: '专线小包',
        kuaididingdan: '快递打单',
        haiwaifahuo: '海外发货',
        caiwuzhongxin: '财务中心',
        syssettings: '系统设置',
        ptdr: '平台管理',

    },
    //二级翻译
    seterj: {
        gnlist: '订单列表',
        gnwaybill: '预报订单',
        gnpilsc: '批量上传',
        ddlist: '订单列表',
        zlist: '订单列表',
        zwaybill: '预报订单',
        zpilsc: '批量上传',
        rcdgl: '入仓总单',
        waybill: '创建运单',
        pilsc: '批量上传',
        hwddlist: '订单列表',
        hwybdd: '预报订单',
        hwplsc: '批量上传',
        xjrk: '库存管理',
        rkjl: '入库记录',
        caiwuls: '财务流水',
        dhzd: '大货账单',
        ddgl: '地址管理',
        qdjg: '渠道价格',
        gdlb: '工单列表',
        xtpz: '系统配置',
        ptgl: '平台管理',
        xbzd: '小包账单',
        dscz: '仓租提醒',
        skugl: 'SKU管理',
        xstj: '销售统计',
        ddxg: '打单相关',
        ptzh: '平台账号',
        skugx: 'SKU关系',
        zcmd: '自传面单',
        hwsf: 'SCANFORM',
        kdsf: 'SCANFORM',
        lsddlb: '临时订单列表',
    },
    // 快递打单-预报订单
    kdyddd: {
        sj_name: '收件姓名',
        sj_tel: '收件电话',
        sj_gs: '收件公司',
        sj_dizhi: '收件地址',
        sj_ycdizhi: '延长地址',
        sj_chengshi: '收件城市',
        sj_zouseng: '收件州省',
        sj_youbian: '收件邮编',
        sj_gj: '目的国家',
        dd_bh: '订单编号',
        sj_zdybz: '自定备注',
        insurance: '投保金额',
        sjxinxi: '收件信息',
        orderSIZE: '订单设置',
        xzsl: '箱子数量',
        unitsize: '单位设置',
        xts: '小提示：',
        xtswenzi: '可以在Excel中编辑好，复制并粘贴到下方表格。',
    },
    // 快递打单-预报订单placeholder
    placeholder: {
        sj_name: '请输入收件姓名',
        sj_tel: '请输入收件电话',
        sj_gs: '请输入收件公司',
        sj_dizhi: '请输入收件地址',
        sj_ycdizhi: '地址过长时使用',
        sj_chengshi: '请输入收件城市',
        sj_zouseng: '请输入收件州省',
        sj_youbian: '请输入收件邮编',
        sj_gj: '请选择国家',
        dd_bh: '为空时自动生成',
        sj_zdybz: '多用于Label上的备注',
        insurance: '为空不投保 (需渠道支持)',
        dizhiku: '保存到收件地址库',
        fbayundan: 'FBA运单',
        xtzlcc: '相同重量尺寸',

    },
    // 快递打单-批量上传
    kdpilsc: {
        pilisngTips: '该功能仅适用于单票单件的订单，一票多件的订单暂时不能批量导入。',
        mobanTips: '模板',
        xzplmbTips: '下载批量模板',
        ddpcTips: '订单批次：',
        zlszTips: '重量设置：',
        scwjTips: '上传文件：',
        ddnumTips: '订单数量：',
        sclistTips: '上传列表',
        xitlistTips: '( 注意:系统仅保留30天的Excel导入记录! )',
        piliangTips: '批量删除',
        pichiTips: '批次',
        fileTips: '文件',
        ordernumTips: '订单数量',
        successnumTips: '成功数量',
        failnumTips: '失败数量',
        uptime1Tips: '上传时间',
        caozuoTips: '操作',
    },
    // 快递打单-订单列表
    kdlist: {
        gjz: '关键字',
        gjzpay: '请输入关键字(订单号/收件人/跟踪号/批量上传批次)',
        pi: '批',
        ysfs: '运输方式',
        ysfspay: '请选择运输方式',
        zlfw: '重量范围',
        zlfwpay: '请输入重量范围(kg)',
        fjdz: '发件地址',
        fjdzpay: '请输入发件地址ID',
        xuan: '选',
        cxfs: '查询方式',
        cxfspay: '请选择批量查询方式',
        dorpay: '订单号或跟踪号批量查询，每行一个(该查询只支持精确查询，不支持模糊查询)',
        kdcx: '快递查询',
        all: '全部',
        ydy: '已打印',
        wdy: '未打印',
        sbdd: '失败订单',
        wtk: '无退款',
        ddk: '待退款',
        ytk: '已退款',
        bntk: '不能退款',
        djz: '冻结中',
        rifw: '日期范围',
        ksrq: '开始日期',
        jsrq: '结束日期',
        jr: '今日',
        zr: '昨日',
        jqr: '近7日',
        jssr: '近30日',
        hcdspt: '回传电商平台',
        ysxc: '已筛选出',
        tddxx: '条订单信息',
        sx: '筛选',
        cz: '重置',
        hc0: '待回传',
        hc1: '回传中',
        hc2: '回传成功',
        hc3: '回传失败',
        cx1: '暂未查询',
        cx2: '等待揽收',
        cx3: '运输途中',
        cx4: '到达待取',
        cx5: '投递失败',
        cx6: '可能异常',
        cx7: '签收成功',
        ssjg: '试算价格',
        sckdd: '生成快递单',
        dyxtbq: '打印系统标签',
        tswlpt: '推送物流平台',
        zzt: '子状态',
        mdxz: '面单下载',
        mdxz1: '合并下载',
        mdxz2: ' 打包下载（按订单号）',
        mdxz3: '打包下载（按跟踪号）',
        mdxz4: '打包下载（按运单号）',
        cjdd: '创建订单',
    }

}