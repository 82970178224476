import request from '@/utils/request'
// let token=window.localStorage.getItem("token")
// console.log(token,'llll');
//登陆
export function login(data) {
  return request({
    url: '/user/login.php',
    method: 'post',
    data
  })
}
//
export function indexout(data) {
  return request({
    url: '/index.php',
    method: 'post',
    data
  })
}
//后台登陆
export function login_ht(data) {
  return request({
    url: '/user/login_ht.php',
    method: 'post',
    data
  })
}

//注册
export function logout(data) {
  return request({
    url: '/user/register.php',
    method: 'post',
    // headers: {
    //   "Authorization":"Bearer "+window.localStorage.getItem("token")
    // },
    data
  })
}
//修改密码
export function update_pass(data) {
  return request({
    url: '/user/update_pass.php',
    method: 'post',
    data
  })
}
//常用地址
export function cydz(data) {
  return request({
    url: '/order/list_cydz.php',
    method: 'post',
    data
  })
}
//Fba地址
export function fbadizhi(data) {
  return request({
    url: '/order/list_fba.php',
    method: 'post',
    // headers: {
    //   "Authorization":token
    // },
    data
  })
}
//国家列表
export function getCountry(data) {
  return request({
    url: '/address/country.php',
    method: 'post',
    // headers: {
    //   "Authorization":token
    // },
    data
  })
}
//添加收件地址
export function addAddress(data) {
  return request({
    url: '/address/add.php',
    method: 'post',
    // headers: {
    //   "Authorization":token
    // },
    data
  })
}
//添加fba地址
export function addFbaliat(data) {
  return request({
    url: '/address/add.php',
    method: 'post',
    // headers: {
    //   "Authorization":token
    // },
    data
  })
}
//批量上传
export function upload(data) {
  return request({
    url: '/order/upload_excels.php',
    method: 'post',
    headers: {
      "Content-type": 'application/from-data'
    },
    data
  })
}
//提交excel
export function export_batch_order(data) {
  return request({
    url: '/order/import_batch_order.php',
    method: 'post',
    // headers: {
    //   "Content-type":'application/from-data'
    // },
    data
  })
}
//创建运单
export function addlist(data) {
  return request({
    url: '/order/add.php',
    method: 'post',
    // headers: {
    //   "Content-type":'application/from-data'
    // },
    data
  })
}
//创建运单
export function list_uploads(data) {
  return request({
    url: '/order/list_uploads.php',
    method: 'post',
    data
  })
}
//注册配置
export function getConfig(data) {
  return request({
    url: '/user/getConfig.php',
    method: 'post',
    data
  })
}
// 临额申请记录
export function leSqList(data) {
  return request({
    url: '/system/tempcreditlog.php',
    method: 'post',
    data
  })
}
